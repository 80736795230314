import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/service-application`;
const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}`,
  getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
  getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
  create: () => `${RESOURCE_ENDPOINT}`,
  update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
  bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
  dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
  applicationOnlineEkpayPayment: () => `${RESOURCE_ENDPOINT}/application-online-payment`,
  getGrandTotal: () => `${RESOURCE_ENDPOINT}/grand-total`,
  getDashboardData: () => `${RESOURCE_ENDPOINT}/getDashboardData`,
  getTotalApplicationbyTestServiceMapId: () => `${RESOURCE_ENDPOINT}/getTotalApplicationbyTestServiceMapId`,
  getTaskwiseApplications: () => `${RESOURCE_ENDPOINT}/getTaskwiseApplications`,
  getApplicationList: () => `${RESOURCE_ENDPOINT}/getApplicationList`,
  getApplicationsByOrg: () => `${RESOURCE_ENDPOINT}/getApplicationsByOrg`,
  IsRejectAllowed: (applicationId: Number) => `${RESOURCE_ENDPOINT}/getRejectAllowedStatus/${applicationId}`,
}

export default class ServiceApplicationApi {
  public list = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.list();
    return HttpService.get(url, params, headers);
  }

  public getById = (id: any): AxiosPromise<any> => {
    const url = endpoints.getById(id);
    return HttpService.get(url);
  }

  public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getByWhere();
    return HttpService.get(url, params, headers);
  }

  public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.create();
    return HttpService.post(url, payload, params, headers);
  }

  public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.update(id);
    return HttpService.put(url, payload, params, headers);
  }

  public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.updatePartial(id);
    return HttpService.patch(url, payload, params, headers);
  }

  public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.delete(id);
    return HttpService.delete(url, params, headers);
  }

  public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.bulk();
    return HttpService.post(url, payload, params, headers);
  }

  public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.dropdown();
    return HttpService.get(url, params, headers);
  }

  public getApplicationList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getApplicationList();
    return HttpService.get(url, params, headers);
  }

  public getApplicationsByOrg = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getApplicationsByOrg();
    return HttpService.get(url, params, headers);
  }

  public applicationOnlineEkpayPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.applicationOnlineEkpayPayment();
    return HttpService.post(url, payload, params, headers);
  }

  public getDashboardData = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getDashboardData();
    return HttpService.get(url, params, headers);
  }

  public getGrandTotal = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getGrandTotal();
    return HttpService.get(url, params, headers);
  }

  public getTaskwiseApplications = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getTaskwiseApplications();
    return HttpService.get(url, params, headers);
}

public getTotalApplicationbyTestServiceMapId = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getTotalApplicationbyTestServiceMapId();
    return HttpService.get(url, params, headers);
  }

  public IsRejectAllowed = (applicationId: any): AxiosPromise<any> => {
    const url = endpoints.IsRejectAllowed(applicationId);
    return HttpService.get(url);
}

}
